.signatureBgNoImageSrc {
    padding: 17px;
}

.signatureBgImageSrc {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    min-height: 160px;
    margin-top: 49px;
}

/* CSS de https://codesandbox.io/p/sandbox/react-easy-crop-custom-image-demo-y09komm059?file=%2Fsrc%2Fstyles.js%3A2%2C3-43%2C5 */
.signatureContainer {
    width: 100%;
    height: 100%;
    background: rgba(1,1,1,.75);
    z-index: 2000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.cropButton {
    flex-shrink: 0;
    margin-left: 16px;
}

.controls {
    width: 100%;
    padding: 16px;
    display: flex;
    position: relative;
    align-self: flex-end;
    justify-content: center;
}

.sliderContainer {
    display: flex;
    flex: 1;
    align-items: center;
}

.sliderLabel {
    /* Asumiendo que 'xs' es menor que 600px */
    min-width: 65px;
}

@media (max-width: 599px) {
    .sliderLabel {
        /* Reglas adicionales para 'xs' pueden ir aquí si son necesarias */
    }
}

.slider {
    padding: 22px 0;
    margin-left: 16px;
}

@media (min-width: 600px) {
    .slider {
        flex-direction: row;
        align-items: center;
        margin: 0 16px;
    }
}

.sigCanvas {
    background-color: #fff;
}
