.penColorPicker {
    position: absolute;
    width: 100%;
    bottom: 10px;
}

.penColorPickerBtn {
    width: 40px;
    height: 40px;
    border: none;
    margin: 2px;
    border-radius: 10px;
    background-color: #ccc;
    cursor: pointer;
}

.penColorPickerBtn.selected {
    outline: solid 3px #1cac81 !important;
}
