.woopi-mobile-card {
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
}

.woopi-mobile-card .dayName {
    margin-left: 0;
}
.woopi-mobile-card-header {

}
