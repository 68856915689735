/* EmailOverlay.css */
.email-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.email-overlay-content {
    background: white;
    padding: 20px;
    margin: 10px;
    border-radius: 10px;
    text-align: center;
}

.email-overlay-content > .kt-notification-v2__item-icon {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    background-color: #f7f8fa;
    height: 100px;
    width: 100px;
    min-width: 100px;
    border-radius: 50%;
    font-size: 50px;
}

.email-input-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.email-input {
    flex-grow: 1;
    margin-right: 10px;
}

.btn-add, .btn-send, .btn-cancel {
    margin-top: 10px;
}

.added-email {
    background: #f0f0f0;
    padding: 20px 10px;
    margin: 5px 0;
    border-radius: 5px;
    position: relative;
}

.added-email-remove-btn {
    position: absolute;
    right: 10px;
    top: 10px;
}

.email-overlay-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}
