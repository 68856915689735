.woopi-patient-queue {
    display: flex;
    flex-direction: column;
    height: 100vh;
    font-family: Poppins, Arial, sans-serif;
    font-size: 20px;
    font-size: 2vh;
}
.woopi-patient-queue__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}
.woopi-patient-queue__header img {
    height: 40px;
}
.woopi-patient-queue__specialty-selector {
    padding: 5px;
    font-size: 16px;
}
.woopi-patient-queue__patient-info {
    background-color: #d9534f;
    color: #fff;
    text-align: center;
    padding: 20px;
    flex: 0 0 25%;
    align-content: center;
}
.woopi-patient-queue__patient-info h1 {
    margin: 0;
    font-size: 10vh;
    width: 100%;
}
.woopi-patient-queue__patient-info h2 {
    margin: 0;
    font-size: 3vh;
}
.woopi-patient-queue__table-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.woopi-patient-queue__turnos-table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    text-align: left;
    color: #111111;
    font-weight: 500;
    font-size: 3vh;
}
.woopi-patient-queue__turnos-table th,
.woopi-patient-queue__turnos-table td {
    padding: 10px;
    border: 1px solid #ccc;
}
.woopi-patient-queue__turnos-table th {
    background-color: #f2f2f2;
}
.woopi-patient-queue__turnos-table td {
    background-color: #e6e6e6;
}
.woopi-patient-queue__turnos-table .woopi-patient-queue__time,
.woopi-patient-queue__turnos-table .woopi-patient-queue__consultorio {
    text-align: center;
}
.woopi-patient-queue__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f9fa;
    padding: 20px;
    border-top: 1px solid #ccc;
    font-size: 3vh;
}
.woopi-patient-queue__footer img {
    height: 20px;
}

.woopi-patient-queue__voice-selector {
    min-width: 400px
}

.woopi-patient-queue__controls label {
    margin: auto 10px;
}
