/*

dark cyan #163347;
light blue #00B6ED;
green #7ED321;
pink #E3B6FF;
yellow #F8E71C;
red #D0021B;
dark blue #124A63;
grey #637C8E

*/

html,
body,
#root,
.full {
    height: 100%;
    min-height: 100%;
    font-size: 14px;
    overflow-x: hidden;
}

.meeting img {
    vertical-align: middle;
    max-width: 100%;
}

.ag-header {
    background-color: rgb(12, 43, 64);
    z-index: 1000;
    position: relative;
}

.ag-header,
.ag-footer {
    /* flex-basis: 70px; */
    width: 100%;
    /*opacity: .8;*/
    height: 70px;
    color: #FFF;
}

.ag-main {
    width: 100%;
    height: calc(100% - 140px);
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.ag-rounded {
    border-radius: 19px!important;
}

.ag-href {
    color: #FFF;
    cursor: pointer;
}

.ag-href:hover {
    color: #FFF;
}

.meeting.wrapper {
    background: rgb(12, 43, 64);
    /*background: rgb(255, 255, 255);*/
    height: 100%;
}

video {
    max-width: unset;
}

.meeting .ag-header {
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ag-header-lead {
    font-size: 16px;
    line-height: 35px;
}

.meeting .ag-footer {
    padding: 0 30px;
    display: flex;
    align-items: center;
}

.meeting .ag-main {
    position: relative;
}

/*.meeting .ag-footer :first-child {
    margin-right: 80px;
}*/

.header-logo {
    max-width: 200px !important;
    /*height: 34px;*/
    max-height: 54px;
    margin-right: 12px;
    padding: 0 10px;
}

.ag-container {
    width: calc(100% - 60px);
    height: 100%;
    margin: 0 auto;
    background: rgba(255, 255, 255, .1);
    border-radius: 12px;
    display: grid;
    gap: 10px;
    place-items: center;
    grid-template-rows: repeat(12, auto);
    grid-template-columns: repeat(24, auto);
}

.ag-container-agora {
    width: calc(100% - 60px);
    height: 100%;
    margin: 0 auto;
    background: rgba(255, 255, 255, .1);
    border-radius: 12px;
}

.ag-container-in {
    grid-area: span 12 / span 24 / auto / auto;
    text-align: center;
    margin-top: 60px;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.lateFadeIn {
    animation-fill-mode: forwards;
    animation: fadeIn 30s linear normal;
    -o-animation: fadeIn 30s linear normal;
    -webkit-animation: fadeIn 30s linear normal;
    -moz-animation: fadeIn 30s linear normal;
}

.countdown {
    opacity: 0.75;
    position: absolute;
    z-index: 1000;
    margin: auto;
    color: #333;
    /* top: 15%;*/
    bottom: 15%;
    left: 50%;
}
.countdown .inner-countdown {
    background-color: #fff;
    padding: 20px 10px;
    border-radius: 10px;
    border: 1px solid #fff;
    position: relative;
    left: -50%;
}
.inner-countdown:last-child {
    color: red;
}

@keyframes moveIn {
    0% {
        opacity: 0;
        top: -100%;
    }
    100% {
        opacity: 0.75;
        top: 20%;
    }
}

.moveIn {
    animation-fill-mode: forwards;
    animation: moveIn 3s linear normal;
    -o-animation: moveIn 3s linear normal;
    -webkit-animation: moveIn 3s linear normal;
    -moz-animation: moveIn 3s linear normal;
}
