.cardBgImage {
    background-image: url('data:image/svg+xml;base64,CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9Im5vbmUiIHN0cm9rZT0iY3VycmVudENvbG9yIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+CiAgPHBhdGggZD0iTTIzIDE5VjVhMiAyIDAgMCAwLTItMmgtMy4xN2EzIDMgMCAwIDAtNS42NiAwSDVhMiAyIDAgMCAwLTIgMnYxNGEyIDIgMCAwIDAgMiAyaDE2YTIgMiAwIDAgMCAyLTJ6Ij48L3BhdGg+CiAgPGNpcmNsZSBjeD0iMTIiIGN5PSIxMyIgcj0iNCI+PC9jaXJjbGU+Cjwvc3ZnPgo=');
    background-size: 40% 40%;
    background-repeat: no-repeat;
    background-position: center;
}

.cardBgImageSrc {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

/* CSS de https://codesandbox.io/p/sandbox/react-easy-crop-custom-image-demo-y09komm059?file=%2Fsrc%2Fstyles.js%3A2%2C3-43%2C5 */
.cropContainer {
    width: 100%;
    height: 100%;
    background: #333;
    z-index: 2000;
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.cropButton {
    flex-shrink: 0;
    margin-left: 16px;
}

.controls {
    width: 100%;
    padding: 16px;
    display: flex;
    position: relative;
    align-self: flex-end;
    justify-content: center;
}

.sliderContainer {
    display: flex;
    flex: 1;
    align-items: center;
}

.sliderLabel {
    /* Asumiendo que 'xs' es menor que 600px */
    min-width: 65px;
}

@media (max-width: 599px) {
    .sliderLabel {
        /* Reglas adicionales para 'xs' pueden ir aquí si son necesarias */
    }
}

.slider {
    padding: 22px 0;
    margin-left: 16px;
}

@media (min-width: 600px) {
    .slider {
        flex-direction: row;
        align-items: center;
        margin: 0 16px;
    }
}
