/* En tu archivo CSS */
.medication-grid {
    display: grid;
    /*grid-gap: 20px;*/
    grid-template-columns: repeat(auto-fill, minmax(300px, 2fr));
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.medication-grid-card {
    display: flex;
    align-content: center;
}

.medication-remove-button {
    position: absolute;
    right: 10px;
    top: 10px;
}

/* Estilo para pantallas pequeñas (mobile) */
@media (max-width: 767px) {
    .medication-grid {
        grid-template-columns: 1fr;
    }
}

/* En tu archivo CSS */
.chevron-list {
    list-style: none; /* Remueve los marcadores predeterminados */
    padding-left: 0; /* Remueve el padding predeterminado */
}

.chevron-list li::before {
    content: "\00BB"; /* Carácter Unicode para el chevron derecho */
    margin-right: 8px; /* Espacio entre el chevron y el texto */
}

.woopi-input-spinner {
    max-width: 120px;
    margin: auto;
}

.woopi-input-spinner input.form-control {
    text-align: center;
}

@keyframes shakeAndColor {
    0%, 100% {
        transform: translateX(0px);
        filter: hue-rotate(0deg);
    }
    25% {
        transform: translateX(-5px);
        filter: hue-rotate(90deg);
    }
    50% {
        transform: translateX(5px);
        filter: hue-rotate(180deg);
    }
    75% {
        transform: translateX(-5px);
        filter: hue-rotate(270deg);
    }
}

.shake-effect {
    animation: shakeAndColor .5s;
    animation-timing-function: ease-in-out;
}

