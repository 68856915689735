.whatsapp-container {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 1000;
    cursor: pointer;
}

.whatsapp-link {
    display: block;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.whatsapp-icon {
    width: 50px;
    height: 50px;
}

.whatsapp-tooltip {
    position: absolute;
    bottom: 60px;
    left: 0;
    width: 250px; /* Aumentar el ancho del tooltip */
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.tooltip-close {
    float: right;
    color: #999;
    margin-left: 10px;
}
