.fullscreen-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.9);
}

.greencheck-animation-container {
    width: 320px;
    height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-container {
    color: #000;
    background-color: transparent;
    text-align: center;
    margin-top: 10px;
    font-size: 26px;
    width: 80%;
}
